import type * as Sentry from '@sentry/browser';

import { REDIRECT_TO_SENTRY_PROJECT_KEY } from '../const/redirectToSentryProjectKey';

export const eventHasRouteToRedirect = function (event: Sentry.Event): event is Omit<
    Sentry.Event,
    'extra'
> & {
    extra?: { [REDIRECT_TO_SENTRY_PROJECT_KEY]: Array<string | never> };
} {
    return Boolean(
        event.extra &&
            REDIRECT_TO_SENTRY_PROJECT_KEY in event.extra &&
            Array.isArray(event.extra[REDIRECT_TO_SENTRY_PROJECT_KEY]),
    );
};
