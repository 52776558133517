import { v4 as uid } from '@lukeed/uuid';
import * as Sentry from '@sentry/browser';
import { makeFetchTransport, makeMultiplexedTransport, ModuleMetadata } from '@sentry/browser';

import { getSettingStorage } from '@jsmdg/browser-storage';
import { REDIRECT_TO_SENTRY_PROJECT_KEY } from './const/redirectToSentryProjectKey';
import { extractDsnAndReleaseDataFromFragment } from './extractDsnAndReleaseDataFromFragment';
import { eventHasRouteToRedirect } from './types/eventhasRoutetoRedirect.guard';
import { framesHaveMandatorySentryRedirectProperties } from './types/frameHasMandatorySentryRedirectProperties.guard';
import { type SentryWithModuleMetaData } from './types/SentryWithModuleMetaData';
import { stackTraceFrameHasModuleMetaData } from './types/stackTraceFrameHasModuleMetaData.guard';

const setSentryUser = function (scope: typeof Sentry | Sentry.Hub): void {
    let id: string;

    try {
        if (!getSettingStorage().get().sentryUserId) {
            getSettingStorage().setItem('sentryUserId', uid());
        }

        id = getSettingStorage().get().sentryUserId;
    } catch {
        id = `${Date.now()}`;
    }

    scope.setUser({ id });
};

const registerSentryClient = (fragmentId: string, client: Sentry.Hub): void => {
    window.Evelin.sentryClients[fragmentId] = client;
    setSentryUser(client);
};

if (!window.Sentry) {
    window.Sentry = Sentry;
}

if (!window.Evelin.sentryClients) {
    window.Evelin.sentryClients = {};
}

if (!window.Evelin.registerSentryClient) {
    window.Evelin.registerSentryClient = registerSentryClient;
}

const algoliaRequestsRegex =
    /^https:\/\/CM7VJJA0AR-[\da-z]+\.algolia\.net\/1\/indexes\/\*\/queries$/u;

Sentry.init({
    tracePropagationTargets: [window?.location?.origin],
    dsn: window?.Evelin?.data?.julien?.sentryDSN,
    transport: makeMultiplexedTransport(makeFetchTransport, ({ getEvent }) => {
        // This is how sentry recommends event-redirects in a MFE-setup, for more see:
        // https://docs.sentry.io/platforms/javascript/guides/electron/configuration/micro-frontend-support/#combining-modulemetadata-and-makemultiplexedtransport
        const event: Sentry.Event = getEvent();
        if (event && eventHasRouteToRedirect(event)) {
            return event.extra[REDIRECT_TO_SENTRY_PROJECT_KEY];
        }

        return [];
    }),
    integrations: [
        new ModuleMetadata(),
        window?.Evelin?.data?.julien?.sentryTracingEnabled && new Sentry.BrowserTracing(),
        window?.Evelin?.data?.julien?.sentrySessionReplayEnabled &&
            new Sentry.Replay({
                networkDetailAllowUrls: [
                    '/fragments/cart/api/cart/remove-item',
                    algoliaRequestsRegex,
                ],
            }),
    ].filter(Boolean),

    ...(window?.Evelin?.data?.julien?.sentryTracingEnabled && {
        tracesSampleRate: window?.Evelin?.data?.julien?.sentrySampleRate,
    }),

    ...(window?.Evelin?.data?.julien?.sentrySessionReplayEnabled && {
        replaysSessionSampleRate: 0,
        replaysOnErrorSampleRate: window?.Evelin?.data?.julien?.replaysOnErrorSampleRate,
    }),

    autoSessionTracking: true,

    release: `${window?.Evelin?.data?.julien?.release}`,
    environment: window?.Evelin?.data?.julien?.environment,
    debug: false,
    attachStacktrace: true,
    denyUrls: [
        // Facebook flakiness
        /graph\.facebook\.com/i,
        // Facebook blocked
        /connect\.facebook\.net\/en_us\/all\.js/i,
        // Chrome extensions
        /chrome-extension:\//i,
        // Chrome extensions
        /extensions\//i,
        /^chrome:\/\//iu,
        // Safari extension
        /safari-extension:\//i,
        // Other plugins

        // Tiktok timeouts
        /analytics\.tiktok\.com/i,

        /initinstantpage/i,

        /\/pagead\//iu, // https://sentry.io/organizations/jsmdh/issues/1732629736/?_allp=1&query=is%3Aunresolved&sort=freq&statsPeriod=24h Google syntax error
        /src\/transports\/base/i, // Sentry https://sentry.io/organizations/jsmdh/issues/2711506613/events/2804cb77f71b4de19504e13cc8cbd846/?project=1895708&query=is%3Aunresolved&sort=user&statsPeriod=7d
        /usercentrics/i, // Usercentrics https://sentry.io/organizations/jsmdh/issues/2718772024/?_allp=1&query=is%3Aunresolved&sort=user&statsPeriod=7d
        /gtm/iu, // https://sentry.io/organizations/jsmdh/issues/3741584695/?environment=prod&query=is%3Aunresolved&referrer=issue-stream&statsPeriod=24h&utc=true
        /gtag/iu, // https://sentry.io/organizations/jsmdh/issues/3741584695/?environment=prod&query=is%3Aunresolved&referrer=issue-stream&statsPeriod=24h&utc=true
        /browser-ui/i, // https://sentry.io/organizations/jsmdh/issues/3741584695/?environment=prod&query=is%3Aunresolved&referrer=issue-stream&statsPeriod=24h&utc=true
    ],
    // https://docs.sentry.io/platforms/javascript/configuration/filtering/#decluttering-sentry
    ignoreErrors: [
        // Random plugins/extensions
        'top.GLOBALS',
        "Blocked 'script' from 'about:'", // CSP

        // paypal zoid
        'zoid destroyed all components',
        'Detected container element removed from DOM',
        'Component closed',

        // Instagram thing
        "Can't find variable: _AutofillCallbackHandler",

        "undefined is not an object (evaluating 'window.webkit.messageHandlers.selectedTextHandler.postMessage')", // https://sentry.io/organizations/jsmdh/issues/1816768379/?project=1895708&query=is%3Aunresolved&sort=freq&statsPeriod=90d
        'Non-Error promise rejection captured with keys: currentTarget, detail, isTrusted, target', // https://sentry.io/organizations/jsmdh/issues/2164717744/?project=1895708&query=is%3Aunresolved&sort=freq&statsPeriod=90d

        // PhantomJS throws this
        "Expected an identifier but found 're' instead", // https://sentry.io/organizations/jsmdh/issues/2354146889/?project=1895708

        // Firefox error
        'can\'t redefine non-configurable property "userAgent"', // https://sentry.io/organizations/jsmdh/issues/1817133724/events/e5add341ea3f4e54a63bf440e2bf257b/?cursor=0%3A100%3A0&environment=prod&project=1895708&statsPeriod=14d
        "undefined is not an object (evaluating 'Object.keys(this.purposes)')",
        '`beforeSend` returned `null`, will not send event.',

        // remove noise
        /network error/i,
        'NetworkError when attempting to fetch resource.',
        'AxiosError: Network Error',
        'Request aborted',
    ],

    beforeSend(event: Sentry.Event): Sentry.Event | null {
        const stacktraceFrames: SentryWithModuleMetaData[] =
            event.exception?.values?.[0].stacktrace?.frames ?? [];

        const lastFrameWithRedirectRouteArray = stacktraceFrames
            .filter(stackTraceFrameHasModuleMetaData)
            .map(extractDsnAndReleaseDataFromFragment)
            .slice(-1);

        return framesHaveMandatorySentryRedirectProperties(lastFrameWithRedirectRouteArray)
            ? {
                  ...event,
                  extra: {
                      ...event.extra,
                      [REDIRECT_TO_SENTRY_PROJECT_KEY]: lastFrameWithRedirectRouteArray,
                  },
              }
            : event;
    },
});

setSentryUser(Sentry);

window.logToSentry = Sentry.captureException;
